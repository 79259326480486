import { Badge, createStyles } from "@mantine/core";

type MarketplaceBadgeProps = {
    text: string,
    applyLeftMargin?: boolean
}

const useStyles = createStyles((theme, { applyLeftMargin }: MarketplaceBadgeProps) => ({
    badgeWrapper: {
        maxWidth: "5rem",
        display: "inline",
        marginLeft: applyLeftMargin ? '.25rem' : '',
    },
}));

const externalMarketplaces = (function (raw) {
    if (raw) {
        try {
            return JSON.parse(raw!);
        } catch (err) { }
    }
    return null;
})(window?.__RUNTIME_CONFIG__.REACT_APP_EXTERNAL_MARKETPLACES);

export const MarketplaceBadge = ({ text, applyLeftMargin }: MarketplaceBadgeProps) => {
    const { classes } = useStyles({ text, applyLeftMargin });
    if (!externalMarketplaces) return;
    if (!text) return;

    const externalMarketplace = externalMarketplaces.filter((x: any) => x.marketplaceName == text.toLowerCase())[0];

    return (externalMarketplace &&
        <div className={classes.badgeWrapper}>
            <Badge size="xs" variant="filled" style={{ backgroundColor: externalMarketplace.backgroundColor }} >{externalMarketplace.displayText}</Badge>
        </div>);
};