import { useCallback, useState } from "react";
import {
  Group,
  Text,
  Paper,
  Select,
  TextInput,
  ActionIcon,
  createStyles,
  Flex,
} from "@mantine/core";
import {
  SearchKeywordList,
  TicketEnquiryType,
  TicketStatus,
} from "./enquiryInbox.types";
import { useRouterContext } from "@mydeal/core";
import { IconSearch } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  searchContainer: {
    gap: 0,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    borderRadius: 10,
    maxWidth: 250,
    padding: 3,
  },
}));

interface SearchTicketsCardProps {
  status?: TicketStatus;
  onStatusChange?: (val: string) => void;
  isEscalation?: boolean;
  onIsEscalationChange?: (val: boolean) => void;
  enquiryType?: string;
  onEnquiryTypeChange?: (val: string) => void;
  searchKeyword?: string;
  refreshTable?: () => void;
}

const statuses = [
  {
    value: "",
    label: "- All -",
  },
  {
    value: "Open",
    label: "New/Open",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Solved",
    label: "Solved",
  },
  {
    value: "Escalation",
    label: "All Unsolved",
  },
];

const enquiryTypes = [
  {
    value: "",
    label: "- All -",
  },
].concat(
  Object.keys(TicketEnquiryType)
    .map((enquiryType) => ({
      value: enquiryType,
      label: TicketEnquiryType[enquiryType as keyof typeof TicketEnquiryType],
    }))
    .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))
);

const generateSearchUrl = (
  isEscalation: boolean,
  searchKeyInput: string,
  status: string,
  enquiryType: string
) => {
  return `/enquiryinbox/search-tickets/${isEscalation}/${searchKeyInput}${
    status ? `?status=${status}` : ""
  }${enquiryType ? `${status ? "&" : "?"}enquiryType=${enquiryType}` : ""}`;
};

export const SearchTicketsCard = ({
  status: initialStatus,
  onStatusChange,
  isEscalation: initialIsEscalation,
  onIsEscalationChange,
  enquiryType: initialEnquiryType,
  onEnquiryTypeChange,
  searchKeyword: initialSearchKeyword,
  refreshTable,
}: SearchTicketsCardProps) => {
  const { useHistory } = useRouterContext();
  const { classes } = useStyles();
  const { push: navigate } = useHistory();

  const [status, setStatus] = useState<any>(
    initialStatus ? TicketStatus[initialStatus].toString() : ""
  );
  const [searchKeyword, setSearchKeyword] = useState<string>(
    initialSearchKeyword || ""
  );
  const [searchType, setSearchType] = useState<any>(
    initialIsEscalation || false
      ? SearchKeywordList.EscalatedTickets.toString()
      : SearchKeywordList.CustomerTickets.toString()
  );
  const [searchKeyInput, setSearchKeyInput] = useState<string>(
    initialSearchKeyword ? initialSearchKeyword.replace("#", "") : ""
  );
  const [isEscalation, setIsEscalation] = useState<boolean>(
    initialIsEscalation || false
  );
  const [enquiryType, setEnquiryType] = useState<any>(initialEnquiryType || "");

  const ticketTypes = [
    {
      value: SearchKeywordList.CustomerTickets,
      label: SearchKeywordList.CustomerTickets.toString(),
    },
    {
      value: SearchKeywordList.EscalatedTickets,
      label: SearchKeywordList.EscalatedTickets.toString(),
    },
  ];

  const handleSearchKeywordChange = useCallback((event: any) => {
    var val = event.currentTarget.value;
    setSearchKeyword(val);
    setSearchKeyInput(val.replace("#", ""));
  }, []);

  const handleSearchTypeChange = useCallback(
    (val: any) => {
      const isEscalationNew =
        val?.toString() == SearchKeywordList.EscalatedTickets.toString();
      setSearchType(val?.toString());
      setIsEscalation(isEscalationNew);
      if (onIsEscalationChange) {
        onIsEscalationChange(isEscalationNew);
      }
      if (refreshTable) {
        refreshTable();
      }
      navigate(
        generateSearchUrl(isEscalationNew, searchKeyInput, status, enquiryType)
      );
    },
    [
      enquiryType,
      navigate,
      onIsEscalationChange,
      refreshTable,
      searchKeyInput,
      status,
    ]
  );

  const handleStatusChange = useCallback(
    (val: any) => {
      setStatus(val);
      if (onStatusChange) {
        onStatusChange(val);
      }
      if (refreshTable) {
        refreshTable();
      }
      navigate(
        generateSearchUrl(isEscalation, searchKeyInput, val, enquiryType)
      );
    },
    [
      onStatusChange,
      refreshTable,
      navigate,
      isEscalation,
      searchKeyInput,
      enquiryType,
    ]
  );

  const handleEnquiryTypeChange = useCallback(
    (val: any) => {
      setEnquiryType(val);
      if (onEnquiryTypeChange) {
        onEnquiryTypeChange(val);
      }
      if (refreshTable) {
        refreshTable();
      }
      navigate(generateSearchUrl(isEscalation, searchKeyInput, status, val));
    },
    [
      refreshTable,
      navigate,
      isEscalation,
      searchKeyInput,
      status,
      onEnquiryTypeChange,
    ]
  );

  return (
    <>
      <Paper
        p="xl"
        sx={{ position: "relative", marginBottom: "20px" }}
        data-testid="enquiry-search"
        dir="horizontal"
      >
        <Group>
          <Group>
            <Text fz="sm">Searching:</Text>
            <Select
              defaultValue={SearchKeywordList.CustomerTickets.toString()}
              data={ticketTypes}
              value={searchType}
              onChange={(val) => {
                handleSearchTypeChange(val);
              }}
              size="sm"
            />
          </Group>
          <Group>
            <Text fz="sm">Filter by status:</Text>
            <Select
              data={statuses}
              value={status}
              onChange={(val) => {
                handleStatusChange(val);
              }}
              size="sm"
              sx={{ width: "10rem" }}
            />
          </Group>
          {searchType == SearchKeywordList.CustomerTickets.toString() && (
            <Group>
              <Text fz="sm">Filter by enquiry type:</Text>
              <Select
                data={enquiryTypes}
                value={enquiryType}
                onChange={(val) => {
                  handleEnquiryTypeChange(val);
                }}
                size="sm"
                sx={{ width: "20rem" }}
              />
            </Group>
          )}
          <Group>
            <Text fz="sm">
              Search By Ticket ID/Order Group No/Order No/Customer Name
            </Text>
            <Group className={classes.searchContainer}>
              <TextInput
                radius="md"
                placeholder="Search"
                size="sm"
                icon={<IconSearch size={16} stroke={1.5} />}
                styles={{ rightSection: { pointerEvents: "none" } }}
                variant="unstyled"
                onChange={handleSearchKeywordChange}
                value={searchKeyword}
              />
              <ActionIcon
                disabled={!searchKeyword || searchKeyword.trim() === ""}
                component="a"
                href={generateSearchUrl(
                  isEscalation,
                  searchKeyInput,
                  status,
                  enquiryType
                )}
                color="#F04A1C"
                size="lg"
                radius="md"
                variant="filled"
              >
                <IconSearch size="16" />
              </ActionIcon>
            </Group>
          </Group>
        </Group>
      </Paper>
    </>
  );
};
