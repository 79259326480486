import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataProvider, useNotification } from "@mydeal/core";
import { Text, LoadingOverlay, Space, Group } from "@mantine/core";
import { Link } from "@mydeal/react-router";
import { DynamicDataTable, useApiErrorHandler } from "@mydeal/ui-mantine";
import { clientsService } from "@services";
import { ImportShipmentSummary } from "./ImportShipmentSummary";

interface ImportShipmentModelProps {
  onClose: (reload?: boolean) => void;
  importSourceFile?: any;
}

export const ImportShipmentModel = ({
  importSourceFile,
  onClose,
}: ImportShipmentModelProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _clientsService = useMemo(
    () => clientsService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [importedData, setimportedData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const summaryData = useMemo(
    () => [
      {
        label: "Orders imported",
        stats: importedData?.stat.noOfTotalOrders,
        progress: importedData?.stat.noOfTotalOrders,
        color: "yellow",
        icon: "Up",
      },
      {
        label: "orders marked as delivered",
        stats: importedData?.stat.noOfDeliveredOrders,
        progress: importedData?.stat.noOfDeliveredOrders,
        color: "green",
        icon: "Up",
      },
      {
        label: "Orders marked as not delivered",
        stats: importedData?.stat.noOfNotDeliveredOrders,
        progress: importedData?.stat.noOfNotDeliveredOrders,
        color: "red",
        icon: "Up",
      },
    ],
    [
      importedData?.stat.noOfDeliveredOrders,
      importedData?.stat.noOfNotDeliveredOrders,
      importedData?.stat.noOfTotalOrders,
    ]
  );

  const { open: notify } = useNotification();

  const apiErrorHandler = useApiErrorHandler(notify);

  const fetchData = useCallback(() => {
    return importedData;
  }, [importedData]);

  useEffect(() => {
    if (importSourceFile) {
      setLoading(true);
      setimportedData(null);
      _clientsService
        .importShipments(importSourceFile)
        .then((result) => {
          setimportedData(result);
          notify?.({
            type: "success",
            message:
              result?.SuccessMessage || result?.Message || "Import successful",
            description: "",
          });
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [_clientsService, apiErrorHandler, importSourceFile, notify]);

  return (
    <>
      <Space h="xl" />
      <LoadingOverlay visible={loading} />
      <Text size="sm" mt="md">
        <Group spacing="4px">
          If you are experiencing issues please 
          <Link
            to="#"
            onClick={(e) => {
              window.open("https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new", "_blank");
              e.preventDefault();
            }}
          >
            contact us
          </Link>
          with a copy of the tracking file attached and we will assist you
          promptly
        </Group>
      </Text>
      {importedData && (
        <>
          <ImportShipmentSummary data={summaryData} />
          <Space h="xl" />
          <DynamicDataTable<any, any>
            sortable={true}
            fetchData={fetchData}
            getData={(response) => response.output}
            getCount={(response) => response.stat.noOfTotalOrders}
            hasPagination={false}
            mantineTableBodyRowProps={{}}
                columns={[
              {
                fieldName: "lineNum",
                header: "Line No",
                minSize: 100,
                sortable: false,
                cellTemplate: (value: any) => <Text>{value}</Text>,
              },
              {
                fieldName: "MainDeal",
                header: "Order",
                minSize: 100,
                sortable: false,
                cellTemplate: (value: any) => <Text>{value}</Text>,
              },
              {
                fieldName: "input",
                header: "Response",
                minSize: 100,
                cellTemplate: (value: any) => <Text>{value}</Text>,
              },
            ]}
          />
        </>
      )}
    </>
  );
};
