import { useCallback, useMemo, useRef, useState } from "react";
import { PageTitle } from "@components";
import {
  DynamicDataTable,
  IDynamicDataTable,
  IDynamicFetchParameters,
} from "@mydeal/ui-mantine";
import { Text, Paper, LoadingOverlay, Anchor, Card } from "@mantine/core";

import {
  useAuthenticatedApi,
  useDataProvider,
  useRouterContext,
} from "@mydeal/core";
import { enquiryInboxService } from "@services";
import { SearchTicketsCard } from "./searchTicketsCard";
import { TicketPriority, TicketStatus } from "./enquiryInbox.types";
import { IconAlertTriangle } from "@tabler/icons-react";
import { TicketStatusCard } from "./ticketStatusCard";

interface IRouteParams {
  isEscalation: string;
  searchKeyword: string;
}

export const SearchResults = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const { Link } = useRouterContext();
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [resultsCount, setResultsCount] = useState<number | undefined>();
  const [tooManyResults, setTooManyResults] = useState<boolean>(false);
  const { useParams, useLocation } = useRouterContext();
  const { isEscalation: isEscalationInitial, searchKeyword } =
    useParams<IRouteParams>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search ? search : "");
  const status = useRef(queryParams.get("status") || "");
  const enquiryType = useRef(queryParams.get("enquiryType") || "");
  const isEscalation = useRef(!!JSON.parse(isEscalationInitial));

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sort }: IDynamicFetchParameters) => {
      const response = await _enquiryInboxService.searchTickets(
        status.current,
        isEscalation.current,
        enquiryType.current,
        searchKeyword,
        {
          PageIndex: pageIndex,
          PageSize: pageSize,
          Sort: sort.length > 0 ? sort[0].id : "",
          SortType: sort.length > 0 ? (sort[0].desc ? "desc" : "asc") : "",
        }
      );

      const count = response?.ticketModel?.TotalTicket || 0;

      setTooManyResults(count >= 1000);
      setResultsCount(count);

      return response;
    },
    [_enquiryInboxService, status, isEscalation, enquiryType, searchKeyword]
  );

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const dtRef = useRef<IDynamicDataTable>(null);

  const refreshTable = useCallback(() => {
    if (dtRef.current) {
      dtRef.current.refresh();
    }
  }, []);

  const onStatusChange = useCallback(
    (val: string) => (status.current = val),
    [status]
  );
  const onEnquiryTypeChange = useCallback(
    (val: string) => (enquiryType.current = val),
    [enquiryType]
  );
  const onIsEscalationChange = useCallback(
    (val: boolean) => (isEscalation.current = val),
    [isEscalation]
  );

  return (
    <>
      <PageTitle
        title="Search Result"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Search Result" },
        ]}
      />
      <SearchTicketsCard
        status={
          status
            ? TicketStatus[status.current as keyof typeof TicketStatus]
            : undefined
        }
        onStatusChange={onStatusChange}
        isEscalation={isEscalation.current}
        onIsEscalationChange={onIsEscalationChange}
        enquiryType={enquiryType.current}
        onEnquiryTypeChange={onEnquiryTypeChange}
        searchKeyword={searchKeyword}
        refreshTable={refreshTable}
      />
      <Card withBorder mih={300} p="xl">
        {typeof resultsCount !== "undefined" && (
          <Text size="md" sx={{paddingLeft: "1.5rem"}}>
            {resultsCount === 0
              ? "No results found."
              : `${resultsCount} results returned.`}
            {tooManyResults ? " Only the first 1000 will be displayed. Apply filters to reduce the results returned." : ""}
          </Text>
        )}
        <Paper p="xl" sx={{ position: "relative" }}>
          <LoadingOverlay visible={loading} />
          {!loading && (
            <DynamicDataTable<any, any>
              sortable={true}
              fetchData={fetchData}
              getData={(response) => response?.ticketModel?.TicketModels}
              getCount={(response) => response?.ticketModel?.TotalTicket || 0}
              controlRef={dtRef}
              mantineTableBodyRowProps={{}}
              columns={[
                {
                  fieldName: "TicketId",
                  header: "Ticket ID",
                  size: 100,
                  sortable: true,
                  cellTemplate: (value: any, row: any) => (
                    <>
                      <Anchor
                        component={Link}
                        to={`/EnquiryInbox/ViewTicket/${value}`}
                      >
                        <span>#</span>
                        {value} <span> </span>
                      </Anchor>
                      {row.Priority === TicketPriority.High && (
                        <IconAlertTriangle color="red" size={14} />
                      )}
                    </>
                  ),
                },
                {
                  fieldName: "Priority",
                  header: "Priority",
                  size: 100,
                  sortable: true,
                  cellTemplate: (value: any) => <Text>{value}</Text>,
                },
                {
                  fieldName: "Subject",
                  header: "Subject",
                  size: 250,
                  sortable: true,
                  cellTemplate: (value: any, row: any) => (
                    <>
                      <TicketStatusCard
                        hasFreightProtection={false}
                        isEscalation={row.IsEscalation}
                        isSellerEscalation={false}
                        ticketStatus={row.Status}
                      />
                      <Text>{value}</Text>
                    </>
                  ),
                },
                {
                  fieldName: "RequesterName",
                  header: "From",
                  size: 100,
                  sortable: true,
                  cellTemplate: (value: any, row: any) => {
                    var currentRow = row.Status;
                    return (
                      <>
                        <Text>
                          {currentRow.IsEscalation ? value : "MyDeal"}
                        </Text>
                      </>
                    );
                  },
                },
                ...(!isEscalation.current
                  ? [
                      {
                        fieldName: "OrderGroupId",
                        header: "Order Group No",
                        size: 90,
                        sortable: false,
                        cellTemplate: (value: any) => <Text>{value}</Text>,
                      },
                      {
                        fieldName: "OrderId",
                        header: "Order No",
                        size: 90,
                        sortable: false,
                        cellTemplate: (value: any) => <Text>{value}</Text>,
                      },
                    ]
                  : []),
                {
                  fieldName: "CreatedDatePretty",
                  header: "Created Date",
                  size: 150,
                  sortable: true,
                  cellTemplate: (value: any) => <Text>{value}</Text>,
                },
              ]}
            />
          )}
        </Paper>
      </Card>
    </>
  );
};
