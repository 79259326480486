import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Text,
  Paper,
  LoadingOverlay,
  Card,
  Anchor,
  Badge,
} from "@mantine/core";
import {
  useDataProvider,
  useRouterContext,
  useNotification,
  useAuthenticatedApi,
} from "@mydeal/core";
import { JobService } from "@services";
import Moment from "react-moment";
import { PageTitle } from "@components";
import {
  DynamicDataTable,
  IDynamicDataTable,
  IDynamicFetchParameters,
} from "@mydeal/ui-mantine";
import { IconDownload } from "@tabler/icons-react";
import { JobMasterKey, JobState } from "./job.types";
import { IconExternalLink } from "@tabler/icons-react";
import { openConfirmModal } from "@mantine/modals";

export const RequestDashboard = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _jobService = useMemo(
    () => JobService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { Link } = useRouterContext();
  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setModel] = useState<any>();
  const [selectedJobId, setSelectedJobId] = useState<any>();
  const [jobCancelling, setjobCancelling] = useState<boolean>(false);
  const dataTableRef = useRef<IDynamicDataTable>(null);

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sort }: IDynamicFetchParameters) => {
      const result = await _jobService.loadJobs({
        PageIndex: pageIndex,
        PageSize: pageSize,
        Sort: sort.length > 0 ? sort[0].id : "",
        SortType: sort.length > 0 ? (sort[0].desc ? "desc" : "asc") : "",
      });
      setModel(result);
      return result;
    },
    [_jobService]
  );

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const handleCancelJobClick = (jobId: string) => {
    setSelectedJobId(jobId);
    openConfirmModal({
      title: (
        <Text fw="bold" fz="sm">
          Please Confirm
        </Text>
      ),
      children: (
        <Text size="sm">Are you sure you want to cancel this "Job"?</Text>
      ),
      labels: { confirm: "Yes", cancel: "No" },
      onConfirm: () => {
        setjobCancelling(true);
        _jobService
          .cancelJob(jobId)
          .then((result: any) => {
            notify?.({
              type: "success",
              message: "Request cancelled.",
              description: "",
            });
          })
          .catch((err) => {
            const {
              response: {
                data: { ErrorMessage, title },
              },
            } = err;
            notify?.({
              type: "error",
              message:
                ErrorMessage ??
                "Unable to process the request. Please try again later.",
              description: ErrorMessage ?? title,
            });
          })
          .finally(() => {
            setjobCancelling(false);
            setLoading(false);
            if (dataTableRef.current) {
              dataTableRef.current.refresh();
            }
          });
      },
    });
  };

  return (
    <>
      <PageTitle
        title="Request Dashboard"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Deals", route: "/mydeals" },
          { name: "Request Dashboard" },
        ]}
      />
      <Paper p="xl" sx={{ position: "relative" }}>
        <Card mih={200}>
          <LoadingOverlay visible={loading} />
          {!loading && (
            <>
              <DynamicDataTable<any, any>
                sortable={true}
                fetchData={fetchData}
                getData={(response) => response?.jobModels}
                getCount={(response) => response?.totalCount}
                controlRef={dataTableRef}
                mantineTableBodyRowProps={{}}
                columns={[
                  {
                    fieldName: "JobId",
                    header: "ID",
                    size: 200,
                    sortable: false,
                    cellTemplate: (value: any) => <Text>{value}</Text>,
                  },
                  {
                    fieldName: "JobName",
                    header: "Description",
                    size: 200,
                    sortable: false,
                    cellTemplate: (value: any) => <Text>{value}</Text>,
                  },
                  {
                    fieldName: "RequestedDate",
                    header: "Requested Date / Time",
                    size: 300,
                    cellTemplate: (value: any) => (
                      <Moment format="DD/MM/YYYY HH:mm">{value}</Moment>
                    ),
                  },
                  {
                    fieldName: "State",
                    header: "Status",
                    size: 200,
                    sortable: false,
                    cellTemplate: (value: any, row: any) => {
                      var color =
                        value == JobState.Failed ||
                          (value == JobState.Succeeded &&
                            row.FailedCount &&
                            row.FailedCount > 0)
                          ? "red"
                          : value == JobState.Succeeded
                            ? "green"
                            : "black";
                      var statusText = "";
                      switch (value.toString()) {
                        case JobState.Queued.toString(): {
                          statusText = "Pending";
                          break;
                        }
                        case JobState.Succeeded.toString(): {
                          statusText = "Done";
                          break;
                        }
                        case JobState.Failed.toString(): {
                          statusText = "Failed";
                          break;
                        }
                        case JobState.Cancelled.toString(): {
                          statusText = "Cancelled";
                          break;
                        }
                        case JobState.ProcessingOnImportService.toString(): {
                          statusText = "Processing";
                          break;
                        }
                        default: {
                          statusText = value;
                          break;
                        }
                      }

                      return (
                        <>
                          {row.ResultSummary && row.ResultSummary !== "" ? (
                            <Badge
                              size="sm"
                              variant="filled"
                              color={color}
                            >{`${statusText} - ${row.ResultSummary}`}</Badge>
                          ) : (
                            <Badge size="sm" variant="filled" color={color}>
                              {statusText}
                            </Badge>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    fieldName: "JobMasterKey",
                    header: "Actions",
                    size: 200,
                    sortable: false,
                    cellTemplate: (value: any, row: any) => {
                      var currentRow = row;

                      if (currentRow.State == JobState.Queued) {
                        return (
                          <Button
                            onClick={() =>
                              handleCancelJobClick(currentRow.JobId)
                            }
                            loading={
                              jobCancelling && selectedJobId == currentRow.JobId
                            }
                          >
                            Cancel
                          </Button>
                        );
                      } else if (
                          (currentRow.JobMasterKey == JobMasterKey.MarketplaceProductExport ||
                           currentRow.JobMasterKey == JobMasterKey.MarketplaceProductBannerExport)
                          && currentRow.State == JobState.Succeeded
                      ) {
                        return (
                          <>
                            <Anchor href={currentRow.Result}>
                              <IconDownload size={14} /> Download
                            </Anchor>
                          </>
                        );
                      } else if (
                          (currentRow.JobMasterKey == JobMasterKey.MarketplaceProductExport ||
                           currentRow.JobMasterKey == JobMasterKey.MarketplaceProductBannerExport)
                          && currentRow.State == JobState.Failed
                      ) {
                        return (
                          <Anchor
                            href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
                            target="_blank"
                          >
                            Contact us <IconExternalLink size={14} />
                          </Anchor>
                        );
                      } else if (
                        currentRow.JobMasterKey ==
                        JobMasterKey.MarketplaceProductImport &&
                        (currentRow.State == JobState.Succeeded ||
                          currentRow.State == JobState.Failed)
                      ) {
                        return (
                          <Anchor
                            component={Link}
                            to={`/job/productImportResult/${currentRow.JobId}`}
                          >
                            View Result
                          </Anchor>
                        );
                      } else {
                        return <Text></Text>;
                      }
                    },
                  },
                ]}
              />
            </>
          )}
        </Card>
      </Paper>
    </>
  );
};
