import React from "react";
import { BoxArrowUpRight } from "@mydeal/icons";
import * as yup from "yup";
import { PropsWithChildren, useState, useMemo, useCallback } from "react";
import { useDataProvider, useNavigation, useNotification } from "@mydeal/core";
import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Group,
  LoadingOverlay,
  Stack,
  Title,
} from "@mantine/core";
import { dealsService } from "@services/deals.service";
import { useFocusableForm, useApiErrorHandler } from "@mydeal/ui-mantine";

export interface IExportCardProps {
  exportTitle: string;
  exportUrl?: string;
  enabled?: boolean;
  isProduct?: boolean;
  isUncategorised?: boolean;
  showCheckbox?: boolean;
  redirectUrl?: string;
  description?: React.ReactNode;
  moreInfo?: string;
  queryParameter?: string;
  isProductStatus?: boolean;
}

interface IExportModel {
  includeDiscontinuedProducts: boolean;
}

export const ExportCard: React.FC<PropsWithChildren<IExportCardProps>> = ({
  exportTitle,
  exportUrl,
  enabled = true,
  isProduct = false,
  isUncategorised = false,
  isProductStatus = false,
  showCheckbox = true,
  redirectUrl,
  description,
  moreInfo,
  queryParameter,

  children,
}) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _dealsService = useMemo(
    () => dealsService(dataProviderInstance),
    [dataProviderInstance]
  );

  const { open: notify } = useNotification();
  const { push } = useNavigation();
  const apiErrorHandler = useApiErrorHandler(notify);

  const [downloading, setDownloading] = useState<boolean>(false);
  const formExport = useFocusableForm<IExportModel>({
    schema: yup.object(),
    initialValues: { includeDiscontinuedProducts: true },
  });

  const onExport = useCallback(
    (data: Partial<IExportModel>) => {
      if (exportUrl) {
        setDownloading(true);

        if (isProduct) {
          if (isUncategorised) {
            _dealsService
              .exportUncategorisedProducts()
              .then((result: any) => {
                notify?.({
                  type: "success",
                  message:
                    result.SuccessMessage ||
                    result.Message ||
                    "Export successful",
                  description: "",
                });
              })
              .catch(apiErrorHandler)
              .finally(() => {
                setDownloading(false);
              });
          } else {
            _dealsService
              .exportProducts(data.includeDiscontinuedProducts ?? false)
              .then((result: any) => {
                if (redirectUrl) {
                  push(redirectUrl);
                }
                notify?.({
                  type: "success",
                  message:
                    result.SuccessMessage ||
                    result.Message ||
                    "Export successful",
                  description: "",
                });
              })
              .catch(apiErrorHandler)
              .finally(() => {
                setDownloading(false);
              });
          }
        } else if (isProductStatus) {
          _dealsService
            .exportProductStatus(
              data.includeDiscontinuedProducts ?? false,
              queryParameter
            )
            .then((result: any) => {
              if (redirectUrl) {
                push(redirectUrl);
              }
              notify?.({
                type: "success",
                message:
                  result.SuccessMessage ||
                  result.Message ||
                  "Export successful",
                description: "",
              });
            })
            .catch(apiErrorHandler)
            .finally(() => {
              setDownloading(false);
            });
        } else {
          _dealsService
            .exportStuff(
              exportUrl,
              data.includeDiscontinuedProducts ?? false,
              queryParameter
            )
            .then((result: any) => {
              notify?.({
                type: "success",
                message:
                  result.SuccessMessage ||
                  result.Message ||
                  "Export successful",
                description: "",
              });
            })
            .catch(apiErrorHandler)
            .finally(() => {
              setDownloading(false);
            });
        }
      }
    },
    [_dealsService, apiErrorHandler, exportUrl, isProduct, notify]
  );
  return (
    <Card withBorder>
      <Card.Section
        withBorder
        p="sm"
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[1],
        })}
      >
        <Group position="apart">
          <Title order={5}>{exportTitle}</Title>
          <div>
            {moreInfo && (
              <Anchor href={moreInfo} target="_blank" fw={400}>
                (More info <BoxArrowUpRight size={12} /> )
              </Anchor>
            )}
          </div>
        </Group>
      </Card.Section>
      <Card.Section p="sm">
        <form onSubmit={formExport.onSubmit(onExport)} noValidate>
          <LoadingOverlay visible={downloading} />
          <Stack align="center">
            {showCheckbox && (
              <Checkbox
                label="Include Discontinued Products"
                {...formExport.getInputProps("includeDiscontinuedProducts")}
                defaultChecked={true}
                disabled={!exportUrl}
              />
            )}
            {description}
            <Button type="submit" loading={downloading} disabled={!exportUrl}>
              Export
            </Button>
          </Stack>
        </form>
      </Card.Section>
    </Card>
  );
};
