import { ThemeContext } from "@emotion/react";
import {
  Container,
  Tabs,
  useMantineTheme,
  createStyles,
  Button,
  Center,
  Autocomplete,
  Affix,
  Transition,
  Box,
} from "@mantine/core";
import { IconArrowUp } from "@tabler/icons-react";
import { TabBenefits } from "./TabBenefits";
import { TabCaseStudies } from "./TabCaseStudies";
import { TabHowItWorks } from "./TabHowItWorks";
import { TabPricingPayment } from "./TabPricingPayment";
import { TabPromotion } from "./TabPromotion";
import { useWindowScroll } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing.lg,
    paddingTop: theme.spacing.lg,
    backgroundColor: ` ${
      theme.colorScheme === "dark" ? theme.white : theme.white
    }`,
    borderRadius: theme.spacing.sm,
  },

  tabOnHeaderContainer: {
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  tabTabHead: {
    fontSize: theme.fontSizes.sm,
    padding: theme.spacing.sm,
  },

  tabPanel: {
    paddingBottom: theme.spacing.sm,
  },

  tabTab: {
    fontSize: theme.fontSizes.lg,
    padding: theme.spacing.lg,
    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.md,
      padding: theme.spacing.md,
    },
  },

  btnStartSelling: {
    width: "300px",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: "100%",
    },
  },
}));

export const LandingTab = () => {
  // const theme = useMantineTheme();
  const boxRef = useRef<HTMLDivElement>(null);
  const [tabTop, setTabTop] = useState<number>(0);
  const { classes } = useStyles();
  const [scroll, scrollTo] = useWindowScroll();
  const [activeTab, setActiveTab] = useState<string | null>("first");
  const runtimeConfig = window?.__RUNTIME_CONFIG__ || {};

  // Getting the position of the tabpanel
  const getTabPosition = () => {
    const y = boxRef.current?.offsetTop ?? 0;
    setTabTop(y);
  };

  useEffect(() => {
    getTabPosition();
  }, []);

  // If the window resize taking the position back
  useEffect(() => {
    window.addEventListener("resize", getTabPosition);
  }, []);

  return (
    <>
      <Container
        size="lg"
        className={classes.wrapper}
        data-testid="landingpage-tab"
      >
        {/* Header tab menu */}
        <div ref={boxRef}>
          <Affix sx={{ left: 0, right: 0, top: 60, height: 100 }}>
            <Transition transition="slide-up" mounted={scroll.y > tabTop}>
              {(transitionStyles) => (
                <Container size="lg" className={classes.tabOnHeaderContainer}>
                  <Tabs
                    defaultValue="first"
                    value={activeTab}
                    onTabChange={setActiveTab}
                  >
                    <Tabs.List position="center">
                      <Tabs.Tab value="first" className={classes.tabTabHead}>
                        Benefits
                      </Tabs.Tab>
                      <Tabs.Tab value="second" className={classes.tabTabHead}>
                        Case Studies
                      </Tabs.Tab>
                      <Tabs.Tab value="third" className={classes.tabTabHead}>
                        How It Works
                      </Tabs.Tab>
                      <Tabs.Tab value="forth" className={classes.tabTabHead}>
                        Pricing & Payment
                      </Tabs.Tab>
                      <Tabs.Tab value="fifth" className={classes.tabTabHead}>
                        Promotion
                      </Tabs.Tab>
                    </Tabs.List>
                  </Tabs>
                </Container>
              )}
            </Transition>
          </Affix>
        </div>
        {/* Tab menu in the content */}
        <Tabs defaultValue="first" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List grow position="center">
            <Tabs.Tab value="first" className={classes.tabTab}>
              Benefits
            </Tabs.Tab>
            <Tabs.Tab value="second" className={classes.tabTab}>
              Case Studies
            </Tabs.Tab>
            <Tabs.Tab value="third" className={classes.tabTab}>
              How It Works
            </Tabs.Tab>
            <Tabs.Tab value="forth" className={classes.tabTab}>
              Pricing & Payment
            </Tabs.Tab>
            <Tabs.Tab value="fifth" className={classes.tabTab}>
              Promotion
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="first" className={classes.tabPanel}>
            <TabBenefits />
          </Tabs.Panel>
          <Tabs.Panel value="second" className={classes.tabPanel}>
            <TabCaseStudies />
          </Tabs.Panel>
          <Tabs.Panel value="third" className={classes.tabPanel}>
            <TabHowItWorks />
          </Tabs.Panel>
          <Tabs.Panel value="forth" className={classes.tabPanel}>
            <TabPricingPayment />
          </Tabs.Panel>
          <Tabs.Panel value="fifth" className={classes.tabPanel}>
            <TabPromotion />
          </Tabs.Panel>
        </Tabs>
        <Center style={{ height: 150 }}>
          <Button
            component="a"
            href= {runtimeConfig.REACT_APP_ZOHO_SELLER_APPLICATION_URL}
            size="xl"
            radius="xl"
            className={classes.btnStartSelling}
          >
            Start Selling Now
          </Button>
        </Center>
      </Container>
    </>
  );
};
