export enum JobState {
  Queued = "Queued",
  Failed = "Failed",
  Processing = "Processing",
  ProcessingOnImportService = "ProcessingOnImportService",
  Succeeded = "Succeeded",
  Cancelled = "Cancelled",
  Skipped = "Skipped",
}

export enum JobMasterKey {
  PriceStockUpdateTask = 0,
  RecurringCoreProcessor = 1,
  RecurringCoreImporter = 2,
  CrmCoreProcessor = 3,
  CrmCoreImporter = 4,
  ExternalCoreProcessor = 5,
  BooktopiaCoreProcessor = 6,
  MarketplaceProductImport = 7,
  MarketplaceProductExport = 8,
  FulfillmentExport = 9,
  MarketplaceProductBannerExport = 14
}

export enum WorkItemProductStatus {
  Staged = "Staged",
  Verified = "Verified",
  Skipped = "Skipped",
  Imported = "Imported",
  Failed = "Failed",
}
