import { useState, useEffect, useMemo } from "react";
import * as yup from "yup";
import moment from "moment";
import { useDataProvider } from "@mydeal/core";
import {
  Group,
  Text,
  Title,
  Divider,
  Space,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import { couponService } from "@services/coupon.service";
import { FormDatePicker, useFocusableForm } from "@mydeal/ui-mantine";

interface IExportModel {
  startDateStr: string | Date;
  endDateStr: string | Date;
}

const CouponReportSchema = yup.object({
  startDateStr: yup.date().nullable().required("From Date is required"),
  endDateStr: yup
    .date()
    .nullable()
    .required("To Date is required")
    .test(
      "ValidateDateRange",
      "Please enter valid date range",
      function (endDateStr: Date, context: any) {
        let validDateRange = true;
        if (moment(endDateStr).isBefore(moment(context.parent.startDateStr))) {
          validDateRange = false;
        }
        return validDateRange;
      }
    )
    .test(
      "ValidateInterval",
      "Choose a time interval that is less than or equal to 6 months.",
      function (endDateStr: Date, context: any) {
        let validInterval = true;
        if (
          moment(endDateStr).diff(context.parent.startDateStr, "M", true) >= 6
        ) {
          validInterval = false;
        }
        return validInterval;
      }
    ),
});

export const GetCreditDebitsReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _couponService = useMemo(
    () => couponService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<IExportModel>({
    schema: CouponReportSchema,
    transformValues: (values) => ({
      ...values,
      startDateStr: values.startDateStr
        ? moment(values.startDateStr).format("YYYY-MM-DD")
        : "",
      endDateStr: values.endDateStr
        ? moment(values.endDateStr).format("YYYY-MM-DD")
        : "",
    }),
  });

  const onExport = (data: Partial<IExportModel>) => {
    setLoading(true);
    _couponService
      .exportReport(data.startDateStr || "", data.endDateStr || "")
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    form.setValues({
      startDateStr: new Date(),
    });
  }, []);
 
  return (
    <>
      <Title order={3}>Reports</Title>
      <Divider my="md" />
      <Text size="md">
        Enter a date range and click the download button to get a report of your
        credits and debits related to your Coupon promotion
      </Text>
      <Space h="md" />
      <form onSubmit={form.onSubmit(onExport)}>
        <LoadingOverlay visible={loading} />
        <Group sx={{ alignItems: "self-start" }}>
          <FormDatePicker
            placeholder="From Date"
            withAsterisk
            label="From "
            parentForm={form}
            fieldName="startDateStr"          
          />
          <FormDatePicker
            placeholder="To Date"
            withAsterisk
            label="To "
            parentForm={form}
            fieldName="endDateStr"
          />
        </Group>
        <Space h="md" />
        <Button size="md" type="submit">
          Download
        </Button>
      </form>
    </>
  );
};
