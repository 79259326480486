import React, { useEffect, useState } from "react";
import { PageTitle } from "@components";
import {
  Button,
  Title,
  Text,
  Paper,
  LoadingOverlay,
  Divider,
  Card,
} from "@mantine/core";

import { TicketStatus } from "./enquiryInbox.types";
import { TicketsDataTable } from "./ticketsDataTable";
import { SearchTicketsCard } from "./searchTicketsCard";

export const SolvedEscalations = () => {
  const [ticketsCount, setTicketsCount] = useState<number>(0);
  return (
    <>
      <PageTitle
        title="Recently Solved Escalation"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Solved Escalations" },
        ]}
        description={<Text fz="lg">{`(${ticketsCount} tickets)`}</Text>}
      />
      <SearchTicketsCard status={TicketStatus.Solved} isEscalation={true} />
          <TicketsDataTable
            tableTestId="enquiry-solvedescalation-table"
            ticketStatus={TicketStatus.SolvedEscalation}
            refreshTicketsCount={(i: number) => setTicketsCount(i)}
          />
    </>
  );
};
