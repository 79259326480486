import React from "react";
import { BoxArrowUpRight } from "@mydeal/icons";
import * as yup from "yup";
import { PropsWithChildren, useState, useMemo, useCallback } from "react";
import { useDataProvider, useNavigation, useNotification } from "@mydeal/core";
import {
  Anchor,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Title,
} from "@mantine/core";
import { dealsService } from "@services/deals.service";
import { IconUpload } from "@tabler/icons-react";
import {
  useFocusableForm,
  FormFileInput,
  useApiErrorHandler,
} from "@mydeal/ui-mantine";
import { useDisclosure } from "@mantine/hooks";
import { ImportResult } from "./ImportResult";

export interface IImportCardProps {
  importTitle: string;
  instructionUrl: string;
  templateUrl?: string;
  importUrl: string;
  enabled?: boolean;
  showResult?: boolean;
  redirectUrl?: string;
}
interface IImportModel {
  importFile?: File;
}

export const ImportCard: React.FC<PropsWithChildren<IImportCardProps>> = ({
  importTitle,
  instructionUrl,
  templateUrl,
  importUrl,
  enabled = true,
  showResult = true,
  redirectUrl,
  children,
}) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _dealsService = useMemo(
    () => dealsService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const { push } = useNavigation();
  const [uploading, setUploading] = useState<boolean>(false);
  const form = useFocusableForm<IImportModel>({
    schema: yup.object<{
      [Property in keyof IImportModel]: any;
    }>({
      importFile: yup.mixed().required("Select a file to import"),
    }),
  });
  const apiErrorHandler = useApiErrorHandler(notify);
  const [
    importResultOpened,
    { open: openImportResult, close: closeImportResult },
  ] = useDisclosure(false);
  const [importResult, setImportResult] = useState<any>();
  const onImport = useCallback(
    (data: any) => {
      setUploading(true);

      _dealsService
        .importStuff(importUrl, data)
        .then((result: any) => {
          if (result.WarningMessage) {
            notify?.({
              type: "error",
              message: result.WarningMessage,
              description: "",
            });
          }
          if (showResult) {
            setImportResult(result);
            openImportResult();
          } else {
            if (redirectUrl) {
              push(redirectUrl);
            }
            notify?.({
              type: "success",
              message:
                result.SuccessMessage || result.Message || "Import successful",
              description: "",
            });
          }
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setUploading(false);
        });
    },
    [_dealsService, apiErrorHandler, importUrl, notify]
  );
  return (
    <>
      <Card withBorder>
        <Card.Section
          withBorder
          p="sm"
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.gray[1],
          })}
        >
          <Group position="apart">
            <Title order={6}>{importTitle}</Title>
            <div>
              <Anchor href={instructionUrl} target="_blank" fw={400}>
                (Instructions <BoxArrowUpRight size={12} /> )
              </Anchor>
              {templateUrl && (
                <Anchor href={templateUrl} target="_blank" fw={400}>
                  (Download Template <BoxArrowUpRight size={12} /> )
                </Anchor>
              )}
            </div>
          </Group>
        </Card.Section>
        <Card.Section p="sm">
          <LoadingOverlay visible={uploading} />
          <form onSubmit={form.onSubmit(onImport)} noValidate>
            <Stack>
              <FormFileInput
                icon={<IconUpload size={14} />}
                placeholder="Choose file..."
                clearable
                // clearButtonProps={{
                //   dangerouslySetInnerHTML: { __html: "x" },
                // }}
                fieldName="importFile"
                parentForm={form}
              />
              <Group position="center">
                <Button type="submit" loading={uploading}>
                  Import
                </Button>
              </Group>
            </Stack>
          </form>
        </Card.Section>
      </Card>
      <Modal
        opened={importResultOpened}
        onClose={closeImportResult}
        title="Import Results"
        size="60%"
        closeOnClickOutside={false}
      >
        <ImportResult
          result={importResult}
          title="PO BOX Delivery Import Results"
          close={closeImportResult}
        />
      </Modal>
    </>
  );
};
