import { useMemo, useState } from "react";

import {
  IOrderGroupViewModel,
  VoucherState,
  IOrderViewModel,
  IOrderTrackingModel,
} from "./types";

import { format } from "date-fns";
import { ShippingModal } from "./ShippingModal";
import { SellerNotesModal } from "./SellerNotesModal";
import { AdditionalPaymentModal } from "./AdditionalPaymentModal";
import { OrderTabs } from "./OrderTabs";
import { TrackingModal } from "./TrackingModal";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
  useRouterContext,
} from "@mydeal/core";
import { MarketplaceBadge } from "@components/shared/MarketplaceBadge";
import { orderService } from "@services";
import { PageTitle } from "@components";
import {
  Anchor,
  Badge,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  Modal,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconCalendar,
  IconChevronDown,
  IconDotsCircleHorizontal,
  IconEdit,
  IconMail,
  IconNumber,
  IconReceiptRefund,
  IconTrash,
  IconTruckDelivery,
} from "@tabler/icons-react";
import moment from "moment";

interface IRouteParams {
  dealId: string;
}

export const OrderDetails = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const [model, setModel] = useState<IOrderGroupViewModel>();
  const [currentOrder, setCurrentOrder] = useState<IOrderViewModel>();
  const [currentTrackingModel, setCurrentTrackingModel] =
    useState<IOrderTrackingModel>();
  const [openShippingModal, setOpenShippingModal] = useState<boolean>(false);
  const [openSellerNotesModal, setOpenSellerNotesModal] =
    useState<boolean>(false);
  const [openAdditionalPaymentModal, setOpenAdditionalPaymentModal] =
    useState<boolean>(false);
  const [openTrackingModal, setOpenTrackingModal] = useState<boolean>(false);
  const [addOnRequestReasonSelectList, setAddOnRequestReasonSelectList] =
    useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<any>("");
  const { useParams, Link } = useRouterContext();
  const { dealId } = useParams<IRouteParams>();
  const addOnAllowedMarketplaces = ["mydeal"];
   
  const fetchData = async () => {
    setLoading(true);
    await _orderService
      .getById(Number(dealId))
      .then((result: any) => {
        setModel(result?.Model);
        setAddOnRequestReasonSelectList(result?.AddOnRequestReasonSelectList);
        setPaymentLink(result?.PaymentLinkUrl);
      })
      .finally(() => setLoading(false));
  };

  const toggleShippingModal = (reload?: boolean) => {
    setOpenShippingModal(!openShippingModal);
    if (reload) {
      fetchData();
    }
  };

  const toggleSellerNotesModal = (reload?: boolean) => {
    setOpenSellerNotesModal(!openSellerNotesModal);
    if (reload) {
      fetchData();
    }
  };

  const toggleAdditionalPaymentModal = (reload?: boolean) => {
    setOpenAdditionalPaymentModal(!openAdditionalPaymentModal);
    if (reload) {
      fetchData();
    }
  };

  const toggleTrackingModal = (reload?: boolean) => {
    setOpenTrackingModal(!openTrackingModal);
    if (reload) {
      fetchData();
    }
  };
  const refreshAddOnPayments = (reload?: boolean) => {
    if (reload) {
      fetchData();
    }
  };

  useAuthenticatedApi(dataProviderInstance, () => {
    fetchData();
  });

  const handleRemoveTracking = (BidDealGroupID: number, BidDealID: number) => {
    if (window.confirm("Are you sure to remove tracking info?")) {
      setLoading(true);
      _orderService
        .removeDispatchInfo(BidDealGroupID, BidDealID)
        .then(() => {
          notify?.({
            type: "success",
            message: "Tracking info has been removed successfully.",
            description: "",
          });
          fetchData();
        })
        .catch((err) => {
          notify?.({
            type: "error",
            message: "An error occured. Please try again.",
            description: "",
          });
          setLoading(false);
        })
        .finally();
    }
  };

  const isAddOnAllowed = (marketplace: string) => {
      return !marketplace || addOnAllowedMarketplaces.includes(marketplace.toLowerCase());  //NOTE: This is not fully correct.. TODO: FIX
  };

  const showMarketplaceSource = (marketplace: string) => {
      if (!marketplace) return;      

      const externalMarketplaces = JSON.parse(window?.__RUNTIME_CONFIG__.REACT_APP_EXTERNAL_MARKETPLACES!);      
      return externalMarketplaces.some((x: any) => x.marketplaceName == marketplace.toLowerCase());
  };

  return (
    <>
      <>
        <PageTitle
          title={`Order Details`}
          description={
            <Text fz="lg">Order Group No: {model?.BidDealGroupID}</Text>
          }
          breadcrumbs={[
            { name: "Home", route: "/dashboard" },
            { name: "My Deals", route: "/mydeals" },
            { name: "Order Details" },
          ]}
        />
        <Grid>
          <Grid.Col md={9}>
            <Card withBorder p="lg" mih={200}>
              <LoadingOverlay visible={loading} />
              {model && (
                <>
                  <Card p="xl">
                    <Grid>
                      <Grid.Col md={6}>
                        <Stack spacing="sm">
                          <Text fw="bold">Shipping Address:</Text>
                          <Stack spacing={5}>
                            <Text fw={500} id="RedirectFName_view">
                              {model.RedirectFName} {model.RedirectLName}
                            </Text>
                            <Text id="PostalCompanyName_view">
                              {model.PostalCompanyName}
                            </Text>
                            <div>
                              <Text id="PostalAddress_view">
                                {model.PostalAddress}
                              </Text>
                              <Text id="PostalAddress2_view">
                                {model.PostalAddress2}
                              </Text>
                              <Text id="PostalSuburb_view">
                                {model.PostalSuburb}
                              </Text>
                              <Text id="PostalState_view">
                                {model.PostalState}
                              </Text>
                              <Text id="PostalPostcode_view">
                                {model.PostalPostcode}
                              </Text>
                            </div>
                            <Text id="RedirectPhone_view">
                              {model.RedirectPhone}
                            </Text>
                          </Stack>
                          {!model.IsVoucher && (
                            <>
                              <Group>
                                <Button
                                  variant="outline"
                                  size="xs"
                                  onClick={() => {
                                    setCurrentOrder(model as any);
                                    setOpenShippingModal(true);
                                  }}
                                >
                                  Edit Shipping
                                </Button>
                              </Group>
                            </>
                          )}
                        </Stack>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <div>
                          <strong>Purchase Date</strong>
                        </div>
                        <div>{model.FormattedPurchaseDate}</div>
                        <br />
                        <div>
                          <strong>Shipping Costs</strong>
                        </div>
                        <div>{model.FormattedTotalFreightAmount}</div>
                        <br />
                        
                        <div style={{display: showMarketplaceSource(model.CustomerMarketplace) ? 'inline' : 'none'}}>
                          <strong>Source</strong>
                          <div><MarketplaceBadge text={model.CustomerMarketplace}></MarketplaceBadge></div>
                        </div>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        {!model.CompanyTickUsed && (
                          <>
                            <Card withBorder p="sm">
                              <Divider label="Shipping info" mb="xs" />
                              <Button
                                variant="light"
                                size="xs"
                                onClick={() => {
                                  setCurrentTrackingModel({
                                    BidDealGroupID: model.BidDealGroupID,
                                    TrackingCode: model.TrackingCode,
                                    DispatchCarrier: model.DispatchCarrier,
                                  });
                                  setOpenTrackingModal(true);
                                }}
                              >
                                {!model.IsVoucher
                                  ? "Add Tracking"
                                  : "Edit Status"}
                              </Button>
                            </Card>
                          </>
                        )}
                        {model.IsVoucher && (
                          <Stack spacing="sm">
                            <Text fw="bold">
                              {model.CompanyTickUsed ? (
                                <span>
                                  Used{" "}
                                  {model.DispatchDate &&
                                    moment(new Date(model.DispatchDate)).format(
                                      "DD/MM/YYYY"
                                    )}
                                </span>
                              ) : (
                                <span>Not Used</span>
                              )}
                            </Text>

                            <Text title="Expired Date">
                              Expiry Date:
                              {model.Expires &&
                                moment(new Date(model.Expires)).format(
                                  "DD/MM/YYYY"
                                )}
                            </Text>
                            <Group>
                              <Button
                                variant="outline"
                                size="xs"
                                onClick={() => {
                                  //editTracking(model.BidDealGroupID);
                                }}
                              >
                                Edit Status
                              </Button>
                            </Group>
                          </Stack>
                        )}
                      </Grid.Col>
                    </Grid>
                  </Card>

                  {model.Orders.map((order, index) => {
                    return (
                      <>
                        <Card withBorder key={index} mt="sm">
                          <Card.Section
                            withBorder
                            p="sm"
                            sx={(theme) => ({
                              backgroundColor:
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[7]
                                  : theme.colors.gray[0],
                            })}
                          >
                            <Group position="apart">
                              <Group>
                                <Text fw={700} fz="md">
                                  Order No: {order.BidDealID}
                                </Text>
                                {order.Status === VoucherState.Refunded && (
                                  <Badge>Refunded</Badge>
                                )}
                                {order.DeliveryOnHold && <Badge>On Hold</Badge>}
                              </Group>
                              <Group>
                                <Button
                                  color="red"
                                  size="xs"
                                  leftIcon={<IconReceiptRefund size={16} />}
                                  component={Link}
                                  to={`/order/${order.BidDealID}/refund`}

                                  //href={`/order/Refund?bidDealId=${order.BidDealID}`}
                                >
                                  Issue Refund
                                </Button>

                                <Button
                                  variant="default"
                                  size="xs"
                                  leftIcon={<IconMail size={16} />}
                                  component={Link}
                                  to={`/info/order-help/${order.BidDealID.toString()}`}
                                >
                                  Contact Customer
                                </Button>

                                {/* <UncontrolledDropdown>
                              <DropdownToggle
                                color="outline-secondary"
                                size="sm"
                                caret
                              >
                                More Actions
                              </DropdownToggle>
                              <DropdownMenu>
                                {!order.CompanyTickUsed && (
                                  <DropdownItem
                                    onClick={() => {
                                      setCurrentOrder(order);
                                      setOpenTrackingModal(true);
                                    }}
                                  >
                                    Add Tracking
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  onClick={() => {
                                    setCurrentOrder(order);
                                    setOpenSellerNotesModal(true);
                                  }}
                                >
                                  View/Add Seller Notes
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setCurrentOrder(order);
                                    setOpenAdditionalPaymentModal(true);
                                  }}
                                >
                                  Request Additional Payment
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setCurrentOrder(order);
                                    setOpenShippingModal(true);
                                  }}
                                >
                                  Edit Shipping Address
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown> */}
                                <Menu shadow="md" width={250}>
                                  <Menu.Target>
                                    <Button
                                      variant="default"
                                      size="xs"
                                      rightIcon={<IconChevronDown size={16} />}
                                      leftIcon={
                                        <IconDotsCircleHorizontal size={16} />
                                      }
                                    >
                                      More actions
                                    </Button>
                                  </Menu.Target>

                                  <Menu.Dropdown>
                                    {!order.CompanyTickUsed && (
                                      <Menu.Item
                                        onClick={() => {
                                          setCurrentTrackingModel({
                                            BidDealGroupID:
                                              order.BidDealGroupID,
                                            BidDealID: order.BidDealID,
                                            TrackingCode: order.TrackingCode,
                                            DispatchCarrier:
                                              order.DispatchCarrier,
                                          });
                                          setOpenTrackingModal(true);
                                        }}
                                      >
                                        Add Tracking
                                      </Menu.Item>
                                    )}
                                    <Menu.Item
                                      onClick={() => {
                                        setCurrentOrder(order);
                                        setOpenSellerNotesModal(true);
                                      }}
                                    >
                                      View/Add Seller Notes
                                    </Menu.Item>
                                    {isAddOnAllowed(model.CustomerMarketplace)?
                                    <Menu.Item
                                      onClick={() => {
                                        setCurrentOrder(order);
                                        setOpenAdditionalPaymentModal(true);
                                      }}
                                    >
                                      Request Additional Payment
                                    </Menu.Item>:<></>}
                                    <Menu.Item
                                      onClick={() => {
                                        setCurrentOrder(order);
                                        setOpenShippingModal(true);
                                      }}
                                    >
                                      Edit Shipping Address
                                    </Menu.Item>
                                  </Menu.Dropdown>
                                </Menu>
                              </Group>
                            </Group>
                          </Card.Section>
                          <Card.Section p="sm">
                            <Grid>
                              <Grid.Col md={2}>
                                <Anchor
                                  href={order.DealViewModel.Url}
                                  target="_blank"
                                >
                                  <Image src={order.DealViewModel.Thumbnail} />
                                </Anchor>
                              </Grid.Col>
                              <Grid.Col md={7}>
                                <Stack>
                                  <div className="order-item-row-1">
                                    <Grid>
                                      <Grid.Col md={6}>
                                        <Text className="deal-name">
                                          {order.DealViewModel.MainDeal}
                                        </Text>
                                        {order.SelectedOption && (
                                          <>
                                            <Text className="selection-option-span">
                                              <b>Option:</b>{" "}
                                              {order.SelectedOption}
                                            </Text>
                                          </>
                                        )}
                                        SKU: {order.SKU}
                                      </Grid.Col>
                                      <Grid.Col md={2}>
                                        ${order.CustomerPrice.toFixed(2)}
                                      </Grid.Col>
                                      <Grid.Col md={2}>x {order.Qty}</Grid.Col>
                                      <Grid.Col md={2}>
                                        <Text align="right">
                                          ${order.TotalAmount.toFixed(2)}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                  </div>
                                  <div className="order-item-row-2 col-12">
                                    <Grid>
                                      <Grid.Col md={10}>
                                        <Text>
                                          Shipping:
                                          {order.FreightPostcode && (
                                            <label>
                                              {order.FreightPostcode}
                                            </label>
                                          )}
                                        </Text>
                                      </Grid.Col>
                                      <Grid.Col md={2}>
                                        <Text align="right">
                                          $
                                          {order.TotalFreightAmount
                                            ? order.TotalFreightAmount.toFixed(
                                                2
                                              )
                                            : "0.00"}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                  </div>
                                  <Divider />
                                  <div className="order-item-row-3 col-12">
                                    <Grid>
                                      <Grid.Col md={3} offset={6}>
                                        <Text fw="bold">Total Sale Price</Text>
                                      </Grid.Col>
                                      <Grid.Col md={3}>
                                        <Text align="right" fw="bold">
                                          $
                                          {(
                                            order.TotalAmount +
                                            (order.TotalFreightAmount ?? 0)
                                          ).toFixed(2)}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                  </div>
                                  {order.RefundViewModel.HasRefund && (
                                    <div className="order-item-row-4 col-12">
                                      <Grid>
                                        <Grid.Col md={3} offset={6}>
                                          <Text fw="bold">Refunded</Text>
                                        </Grid.Col>
                                        <Grid.Col md={3}>
                                          <Text align="right" fw="bold">
                                            $-
                                            {Math.abs(
                                              order.RefundViewModel.RefundHistoryModels.filter(
                                                (w: any) => w.Approved
                                              )
                                                .map((x: any) => x.RefundAmount)
                                                .reduce(
                                                  (a: any, b: any) => a + b
                                                )
                                            ).toFixed(2)}
                                          </Text>
                                        </Grid.Col>
                                      </Grid>
                                    </div>
                                  )}
                                  <Divider />
                                  {order.RefundViewModel.HasRefund && (
                                    <div className="order-item-row-5 col-12">
                                      <Grid>
                                        <Grid.Col md={3} offset={6}>
                                          <Text fw="bold">Net Payment:</Text>
                                        </Grid.Col>
                                        <Grid.Col md={3}>
                                          <Text align="right" fw="bold">
                                            $
                                            {(
                                              order.TotalAmount +
                                              (order.TotalFreightAmount ?? 0) -
                                              order.RefundViewModel
                                                .RefundedCustomerPrice
                                            ).toFixed(2)}
                                          </Text>
                                        </Grid.Col>
                                      </Grid>
                                    </div>
                                  )}
                                </Stack>
                              </Grid.Col>
                              <Grid.Col md={3}>
                                <Card withBorder fz="sm">
                                  <Divider label="Shipping" />
                                  {!order.IsVoucher && (
                                    <Stack spacing={"xs"} py="xs">
                                      <Group align={"center"}>
                                        {order.CompanyTickUsed ? (
                                          <Badge color="green">
                                            Dispatched
                                          </Badge>
                                        ) : (
                                          <Badge color="blue">
                                            Pending Dispatch
                                          </Badge>
                                        )}
                                      </Group>
                                      {order.CompanyTickUsed && (
                                        <>
                                          {order.DispatchDate && (
                                            <Group spacing="xs">
                                              <IconCalendar
                                                size={14}
                                                stroke={1}
                                              />
                                              {format(
                                                new Date(order.DispatchDate),
                                                "dd/MM/yyyy"
                                              )}
                                            </Group>
                                          )}
                                          <Group spacing="xs">
                                            <IconTruckDelivery
                                              size={14}
                                              stroke={1}
                                            />
                                            {order.DispatchCarrier}
                                          </Group>
                                          <Group spacing="xs">
                                            <IconNumber size={14} stroke={1} />
                                            {order.TrackingCode}
                                          </Group>
                                          {!order.DeliveryOnHold && (
                                            <Group spacing={5}>
                                              <Button
                                                variant="default"
                                                size="xs"
                                                onClick={() => {
                                                  setCurrentTrackingModel({
                                                    BidDealGroupID:
                                                      order.BidDealGroupID,
                                                    BidDealID: order.BidDealID,
                                                    TrackingCode:
                                                      order.TrackingCode,
                                                    DispatchCarrier:
                                                      order.DispatchCarrier,
                                                  });
                                                  setOpenTrackingModal(true);
                                                }}
                                                leftIcon={
                                                  <IconEdit size={14} />
                                                }
                                              >
                                                Edit
                                              </Button>
                                              <Button
                                                variant="outline"
                                                color="red"
                                                size="xs"
                                                onClick={() => {
                                                  handleRemoveTracking(
                                                    model.BidDealGroupID,
                                                    order.BidDealID
                                                  );
                                                }}
                                                leftIcon={
                                                  <IconTrash size={14} />
                                                }
                                              >
                                                Remove
                                              </Button>
                                            </Group>
                                          )}
                                        </>
                                      )}
                                    </Stack>
                                  )}
                                  {/* <div className="mb-1">
                                    <div
                                      id="tracking-alert"
                                      className="alert alert-success alert-dismissible"
                                    >
                                      <i className="fa fa-check-circle"></i>{" "}
                                      Updated !
                                    </div>
                                  </div> */}
                                </Card>
                              </Grid.Col>
                            </Grid>

                            <OrderTabs
                              order={order}
                              reloadOrderDetails={(reload: boolean) => {
                                refreshAddOnPayments(reload);
                              }}
                              paymentLink={paymentLink}
                            />
                          </Card.Section>
                        </Card>
                      </>
                    );
                  })}
                </>
              )}
            </Card>
          </Grid.Col>
          <Grid.Col md={3}>
            <Card withBorder>
              <Card.Section withBorder p="sm">
                <Title order={5}>FAQ:</Title>
              </Card.Section>
              <Card.Section p="sm">
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10036578637967"
                  target="_blank"
                >
                  How to issue refunds
                </Anchor>
                <br />
                <br />
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10072125140623"
                  target="_blank"
                >
                  How do I upload or edit tracking information?
                </Anchor>
                <br />
                <br />
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/9973850031887"
                  target="_blank"
                >
                  How do I update my stock?
                </Anchor>
                <br />
                <br />
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10080039883663"
                  target="_blank"
                >
                  How do I edit the shipping address?
                </Anchor>
                <br />
                <br />
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </>

      <Modal
        opened={openAdditionalPaymentModal}
        size="lg"
        onClose={() => setOpenAdditionalPaymentModal(false)}
        closeOnClickOutside={false}
        title={
          <Text fw={700}>Additional Payment for {currentOrder?.BidDealID}</Text>
        }
      >
        {currentOrder && (
          <AdditionalPaymentModal
            order={currentOrder}
            AddOnRequestReasonSelectList={addOnRequestReasonSelectList}
            toggle={(reload) => {
              toggleAdditionalPaymentModal(reload);
            }}
          />
        )}
      </Modal>

      <Modal
        opened={openSellerNotesModal}
        size="lg"
        onClose={() => toggleSellerNotesModal(false)}
        closeOnClickOutside={false}
        title={<Text fw={700}>Notes for {currentOrder?.BidDealID}</Text>}
      >
        {currentOrder && (
          <SellerNotesModal
            order={currentOrder}
            toggle={(reload) => {
              toggleSellerNotesModal(reload);
            }}
          />
        )}
      </Modal>

      <Modal
        opened={openTrackingModal}
        size="lg"
        onClose={() => toggleTrackingModal(false)}
        closeOnClickOutside={false}
        title={<Text fw={700}>Add/Edit Tracking</Text>}
      >
        {currentTrackingModel && (
          <TrackingModal
            trackingModel={currentTrackingModel}
            onClose={(reload) => {
              toggleTrackingModal(reload);
            }}
          />
        )}
      </Modal>

      <Modal
        opened={openShippingModal}
        size="lg"
        onClose={() => toggleShippingModal(false)}
        closeOnClickOutside={false}
        title={<Text fw={700}>Edit Shipping Address</Text>}
      >
        {currentOrder && (
          <ShippingModal
            currentBidDealID={model?.CurrentBidDealID}
            order={currentOrder}
            toggle={(reload) => {
              toggleShippingModal(reload);
            }}
          />
        )}
      </Modal>
    </>
  );
};
