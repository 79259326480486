import { useEffect, useState } from "react";
import { PageTitle } from "@components";
import { Paper, Divider, Text, Card } from "@mantine/core";

import { TicketStatus } from "./enquiryInbox.types";
import { TicketsDataTable } from "./ticketsDataTable";
import { SearchTicketsCard } from "./searchTicketsCard";

export const SolvedTickets = () => {
  const [ticketsCount, setTicketsCount] = useState<number>(0);

  useEffect(() => {}, []);

  return (
    <>
      <PageTitle
        title="Solved Tickets"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Solved Tickets" },
        ]}
        description={<Text fz="lg">{`(${ticketsCount} tickets)`}</Text>}
      />
      <SearchTicketsCard status={TicketStatus.Solved} isEscalation={false} />
      <TicketsDataTable
        tableTestId="enquiry-solvedticket-table"
        ticketStatus={TicketStatus.Solved}
        refreshTicketsCount={(i: number) => setTicketsCount(i)}
      />
    </>
  );
};
