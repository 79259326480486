import internal from "node:stream";

export enum TicketStatus {
  New = 0,
  Open = 1,
  Pending = 2,
  Solved = 3,
  Escalation = 4,
  SolvedEscalation = 5,
  Closed = 6,
  Hold = 7,
}

export enum SearchKeywordList {
  CustomerTickets = "Customer Tickets",
  EscalatedTickets = "Escalated Tickets",
}

export enum SatisfactionList {
  ShowAll = "showall",
  goodwithcomment = "goodwithcomment",
  good="good",
  bad="bad",
  badwithcomment="badwithcomment"
}

export enum TicketPriority {
  Urgent = "Urgent",
  High = "High",
  Normal = "Normal",
  Low = "Low",
}
export enum TicketSubmitStatus {
  New = 0,
  Open = 1,
  Pending = 2,
  Solved = 3,
  Closed = 4,
  Hold = 5,
}

export enum TicketUserType {
  MyDeal = "mydeal",
  Seller = "seller",
  Customer = "customer",
}

export enum MyDealHelpdeskTicketingMode {
  RequestReply,
  Readonly,
}

export interface TicketModel {
  TicketId: number;
  ParentId: number | null;
  Subject: string;
  LinkedSubject: string;
  Description: string;
  Status: string;
  IsUnRead: Boolean;
  CreatedDate: string;
  DaysSinceCreated: number;
  CreatedDatePretty: string;
  UpdatedDatePretty: string;
  AgentLastResponseDate: string;
  CustomerLastResponseDate: string;
  SellerLastResponseDate: string | null;
  SellerLastResponseDatePretty:string;
  SolvedDate: string;
  SlaBreachText: string;
  SlaBreachStage: string;
  SlaBreachMetric: string;
  IsSlaBreached: boolean;
  Priority: string;
  OrderId: string;
  OrderGroupId: string;
  RequesterName: string;
  SatisfactionText: string;
  SatisfactionScore: string;
  GroupId: string;
  Tags: string[];
  IsSellerEscalation: boolean;
  IsEscalation: boolean;
  IsSellerOrderHelp: boolean;
  HasFreightProtection: boolean;
}

export interface TicketOrderModel {
  OrderId: number;
  OrderGroupId: number | null;
  PurchaseDate: string | null;
  Qty: number;
  PricePerUnit: number;
  ShippingAmount: number | null;
  TotalAmount: number;
  DealTitle: string;
  DealUrl: string;
  Sku: string;
  SelectedOption: string;
  AddonCount: internal;
  Dispatched: boolean;
  ShippingFullName: string;
  ShippingAddress1: string;
  ShippingAddress2: string;
  ShippingPostal: string;
  ShippingPhone: string;
  ShippingCompanyName: string;
  FreightPostcode: string;
  FreightType: string;
  CustomerMarketplace: string;
}

export interface TicketSatifactionRatingModel {
  Id: number;
  Score: string;
  Comment: string;
  Reason: string;
  UpdatedDate: string;
}
export interface TicketProductQuestionModel {
  DealId: number;
  RequesterName: string;
  ShippingSuburb: string;
  ProductName: string;
  Sku: string;
  Options: string;
  Quantity: number;
  PricePerUnit: number;
  FreightType: number;
  CurrentStock: number;
  DealUrl: string;
  TotalAmount: number;
}
export interface TicketReplyModel {
  Body?: string;
  EscalationBody?: string;
  IsSellerEscalation?: boolean;
  Files?: File[];
  TicketSubmitStatus?: TicketSubmitStatus | null;
}

export interface AttachmentModels {
  FileName: string;
  ContentUrl: string;
  ContentType: string;
  ThumbnailUrl: string;
}
export interface TicketCommentModel {
  AuthorId: string;
  AuthorName: string;
  Body: string;
  HtmlBody: string;
  PlainBody: string;
  Public: boolean;
  AttachmentModels: AttachmentModels[];
  CreatedDate: string;
  AuthorType: string;
  AuthorExternalId: string;
}
export interface AuthorModel {
  AuthorId: string;
  AuthorName: string;
}

export interface TicketSubmitStatusList {
  Text: string;
  Value: string;
  Disabled: boolean;
  Group: string | null;
  Selected: Boolean;
}

export interface TicketDetailViewModel {
  TicketModel: TicketModel;
  OrderModel: TicketOrderModel;
  SatifactionRatingModel: TicketSatifactionRatingModel;
  TicketProductQuestionModel: TicketProductQuestionModel;
  TicketReplyModel: TicketReplyModel;
  CommentModels: TicketCommentModel[];
  TicketSubmitStatusList: TicketSubmitStatusList[];
  LinkedTicketModels: TicketModel[];
  ParentTicketModel: TicketModel;
  SupplierUserModel: AuthorModel;
  MydealHelpdeskAgentUserId: string;
  FlagFailedToFetchTicket: Boolean;
}

export interface ITicketsTableFilterModel { 
  FilterType?: string;
  Value?: string;  
}

export interface ITicketsTableRequestModel {
  PageSize: number;
  PageIndex: number;
  SortType?: string;
  Sort?: string;
  Filters?:ITicketsTableFilterModel[]|null
}

export interface ITicketsSummaryNotificationModel  {
  OpenTicketsCount: number;
  OpenEscalationsCount: number;
  HelpCenterAPIConnectErrorFlag:boolean;
  GetSummary:any;
}

export enum TicketEnquiryType {
  product_assistance = "Product assistance",
  missing_parts = "Missing items",
  not_as_described = "Returns & Refund - Item not as described",
  product_fault = "Returns & Refund - Faulty items",
  change_of_mind = "Change of mind",
  damaged_item_received = "Damaged items",
  quantity_error = "Quantity error",
  incorrect_item_received = "Wrong item received",
  other_aftersales_issue = "Other issue",
  order_not_dispatched_outside_eta = "Order not dispatched outside eta",
  order_on_hold = "Order On Hold",
  order_dispatched_outside_eta = "Order dispatched outside eta",
  track_my_order = "Track my order",
  tracking_not_working = "Tracking not working",
  redelivery = "Redelivery",
  return_to_sender = "Return to sender",
  other_predelivery_issue = "Other predelivery issue",
  authority_to_leave_request = "Authority to leave request",
  amend_my_order = "Amend my order",
  cancellation_order_changes = "Cancellation order changes",
  confirmation_email_not_received = "Confirmation email not received",
}  