import { useState } from "react";
import { PageTitle } from "@components";
import { Text } from "@mantine/core";
import { TicketStatus } from "./enquiryInbox.types";
import { TicketsDataTable } from "./ticketsDataTable";
import { SearchTicketsCard } from "./searchTicketsCard";

export const Escalations = () => {
  const [ticketsCount, setTicketsCount] = useState<number>(0);
  return (
    <>
      <PageTitle
        title="Escalation from MyDeal"
        description={<Text fz="lg">{`(${ticketsCount} tickets)`}</Text>}
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Escalation from MyDeal" },
        ]}
      />
      <SearchTicketsCard status={TicketStatus.Escalation} isEscalation={true} />
      <TicketsDataTable
        tableTestId="enquiry-openescalation-table"
        ticketStatus={TicketStatus.Escalation}
        refreshTicketsCount={(i: number) => setTicketsCount(i)}
      />
    </>
  );
};
