import { PropsWithChildren } from "react";
import { Box, SimpleGrid } from "@mantine/core";
import { ExportCard } from "./ExportCard";
import { ImportCard } from "./ImportCard";

export interface IExportImportCardProps {
  importCards?: {
    title: string;
    importUrl: string;
    instructionUrl: string;
    templateUrl?: string;
    enabled?: boolean;
    showResult?: boolean;
    redirectUrl?: string;
  }[];
  exportCards?: {
    title: string;
    exportUrl?: string;
    enabled?: boolean;
    isProduct?: boolean;
    isUncategorised?: boolean;
    redirectUrl?: string;
    description?: React.ReactNode;
    moreInfo?: string;
    queryParameter?: string;
    isProductStatus?: boolean;
    showCheckbox?: boolean;
  }[];
  enabled?: boolean;
}

export const ExportImportSection: React.FC<
  PropsWithChildren<IExportImportCardProps>
> = ({ importCards, exportCards, enabled = true, children }) => {
  return (
    <>
      {children}
      {enabled && (
        <Box mt="md">
          <SimpleGrid cols={2}>
            {exportCards?.map((card, index) => (
              <ExportCard
                key={index}
                exportTitle={card.title}
                exportUrl={card.exportUrl}
                isProduct={card.isProduct}
                isUncategorised={card.isUncategorised}
                description={card.description}
                redirectUrl={card.redirectUrl}
                moreInfo={card.moreInfo}
                queryParameter={card.queryParameter}
                isProductStatus={card.isProductStatus}
                showCheckbox={card.showCheckbox}
              />
            ))}

            {importCards?.map((card, index) => (
              <ImportCard
                key={index}
                importTitle={card.title}
                instructionUrl={card.instructionUrl}
                templateUrl={card.templateUrl}
                importUrl={card.importUrl}
                enabled={card.enabled}
                showResult={card.showResult}
                redirectUrl={card.redirectUrl}
              />
            ))}
          </SimpleGrid>
        </Box>
      )}
    </>
  );
};
